import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Outlet } from 'react-router-dom';
import { Link } from "react-router-dom";
import quoteAction from "../../store/actions/quote.action";
 
export default function QuoteDisplay(props) {
  const currentQuote = useSelector((state) => state?.quote?.currentQuote);  
  const economyRates = useSelector((state) => state?.rates?.economyRates);  
  const expressRates = useSelector((state) => state?.rates?.expressRates);  
  const [expressRate, setExpressRate] = React.useState(null);
  const [economyRate, setEconomyRate] = React.useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  

  useEffect(() => {  
    console.log("use effect quotes")
    figureOutQuoteByCountryRates();
  }, []);

  function figureOutQuoteByCountryRates(){
    var totalWeight = 0;
    if(currentQuote){
      if(currentQuote.largeBoxes){
        totalWeight +=  (currentQuote.largeBoxes * 30); 
      }
      if(currentQuote.smallBoxes){
        totalWeight +=  (currentQuote.smallBoxes * 30);  
      } 
 
      var countryCode = currentQuote.destinationCountry.CountryCode;  

      for (var key in economyRates) {
        if (key.includes(countryCode)) {
          // console.log("Got express rates for country code", countryCode, ":", economyRates[key][totalWeight]);
          setEconomyRate(economyRates[key][totalWeight]);
          break;  
        }
      }

      for (var key in expressRates) {
        if (key.includes(countryCode)) {
          // console.log("Got express rates for country code", countryCode, ":", expressRates[key][totalWeight]);
          setEconomyRate(expressRates[key][totalWeight]);
          break;  
        }
      } 
      
      console.log("total weight ", totalWeight);  
 
    } 
  }
  

  return (
    <div className="quote-display"> 
        <div className="p-6 text-md text-gray-700 bg-gray-50" style={{display:'flex', flexDirection:'column'}}> 
            <h2 className="text-lg underline"> Order Details </h2>
            <p className="text-md"> From: {currentQuote?.collectionCountry?.Title} to {currentQuote?.destinationCountry?.Title} </p>
            <p className="text-md"> Total Weight (kg):  {(currentQuote?.smallBoxes * 20) + (currentQuote?.largeBoxes * 30)}</p>
            <p className="text-md"> Total Items: {currentQuote?.totalBoxes} </p>
            {currentQuote?.smallBoxes > 0 ? <p className="text-md"> Small Boxes: {currentQuote?.smallBoxes} </p> : null}
            {currentQuote?.largeBoxes > 0 ? <p className="text-md"> Small Boxes: {currentQuote?.largeBoxes} </p> : null}
            <hr className="mb-4"></hr>
            {currentQuote?.actualPrice != null ? <p className="text-md font-bold"> Price: £{currentQuote?.actualPrice}  </p> : null}
            <hr className="mb-4"></hr>
            {/* TODO add full addr */}
            <div>
              <p className="text-md break-words">Collection Address: 
                {[ 
                  currentQuote?.collectionAddress?.AddressLineOne?.toUpperCase(), 
                  currentQuote?.collectionAddress?.City?.toUpperCase(), 
                  currentQuote?.collectionAddress?.State?.toUpperCase(), 
                  currentQuote?.collectionAddress?.County?.toUpperCase(), 
                  currentQuote?.collectionCountry?.postalCode?.toUpperCase() 
                ]
                .filter(Boolean) 
                .join(', ')}  
                </p>
            </div>
            <div>
            <p className="text-md break-words">
              Delivery Address:
              {[
                currentQuote?.destinationAddress?.AddressLineOne?.toUpperCase?.(), 
                currentQuote?.destinationAddress?.City?.toUpperCase?.(), 
                currentQuote?.destinationAddress?.County?.toUpperCase?.(), 
                currentQuote?.destinationAddress?.Country?.toUpperCase?.(), 
                currentQuote?.destinationCountry?.postalCode?.toUpperCase?.()
              ]
              .filter(Boolean)  
              .join(', ')}      
            </p>
            </div>
        </div>

        

        <div>
            <p className="text-xs p-6"> 
             Please note that we do not accept backpacks or duffel bags.
              For every item which exceeds the weight or size restrictions 
              you will be charged extra in 
              accordance with our terms and conditions.
             </p>
        </div>

       

    </div>

    
  );
}  
  