export const COMMODITY_CODES = [
    {
        CommodityDescription: "Commodity 1",
        CommodityCode: 'AW100241'
    },
    {
        CommodityDescription: "Commodity 2",
        CommodityCode: 'Code2'
    },
    {
        CommodityDescription: "Commodity 3",
        CommodityCode: 'Code3'
    }
]